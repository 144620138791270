import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Link } from "gatsby";

const IndexPage = () => (
	<Layout>
		<SEO title="404" />
		<section className="notfound">
			<div className="notfound-content ">
				<div className="container">
					<h2>
						404
						<br />
						<h3 className="subtitle">Page not found</h3>
					</h2>
					<Link className="btn btn-outline btn-xl" to="/">
						Go back
					</Link>
				</div>
			</div>
			<div className="overlay" />
		</section>
	</Layout>
);

export default IndexPage;
